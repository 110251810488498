import { isArray } from 'lodash';
const RAW_FIELDS = require('@/views/testing/_resources/sbx-transaction-fields.json');

const SBX_TRANSACTION_FIELDS = RAW_FIELDS.map((f) => {
  let label = f.label;
  if (f.label.startsWith('Exp Line')) {
    label = f.label.replace('Exp Line ', '');
  } else if (f.label.startsWith('Exp ')) {
    label = f.label.replace('Exp  ', '');
  } else if (f.label !== 'Inv Number' && f.label.startsWith('Inv ')) {
    label = f.label.substring(4);
  }

  let key = f.key.toUpperCase();
  if (key === 'RECORDSTATUS') {
    key = f.key;
  }

  return Object.freeze({
    ...f,
    key,
    label,
  });
});

const SBX_TRANSACTION_FIELDS_MAP = SBX_TRANSACTION_FIELDS.reduce((acc, o) => {
  acc[o.key] = o;
  return acc;
}, {});

const SBX_INVOICE_FIELDS = SBX_TRANSACTION_FIELDS.filter((field) => {
  return !(
    field.key.startsWith('LINE') ||
    field.key.startsWith('EXPECTED.LINE') ||
    field.key.startsWith('RESULTS.LINE') ||
    field.key.startsWith('RESULTS.TAX') ||
    field.key.startsWith('ACTUAL.LINE') ||
    field.key.startsWith('ACTUAL.TAX')
  );
});

const SBX_LINE_FIELDS = SBX_TRANSACTION_FIELDS.filter((field) => {
  const key = field.key.toUpperCase();
  return (
    key.startsWith('LINE') ||
    key.startsWith('EXPECTED.LINE') ||
    key.startsWith('RESULTS.LINE') ||
    key.startsWith('RESULTS.TAX') ||
    key.startsWith('ACTUAL.LINE') ||
    key.startsWith('ACTUAL.TAX')
  );
});

const SBX_LINE_FIELDS_MAP = SBX_LINE_FIELDS.reduce((acc, o) => {
  if (!acc) {
    let v = {};
    v[o.key.toUpperCase()] = o;
    return v;
  } else {
    acc[o.key.toUpperCase()] = o;
    return acc;
  }
}, {});

const SBX_INVOICE_FIELDS_MAP = SBX_INVOICE_FIELDS.reduce((acc, o) => {
  acc[o.key] = o;
  return acc;
}, {});

const SBX_ADDRESS_FIELD_NAMES = {
  ship_from: true,
  ship_to: true,
  bill_to: true,
  buyer_primary: true,
  seller_primary: true,
  order_origin: true,
  order_acceptance: true,
  supply: true,
  middleman: true,
};

const SBX_ADDRESS_FIELD_ORDERS = {
  ship_from: 0,
  ship_to: 1,
  bill_to: 2,
  buyer_primary: 3,
  seller_primary: 4,
  order_origin: 5,
  order_acceptance: 6,
  supply: 7,
  middleman: 8,
};

// const SBX_REWRITE_FIELDS = {
//   'invoice.ship_to': 'invoice_ship_to',
//   'invoice.bill_to': 'invoice_bill_to',
//   'invoice.ship_from': 'invoice_ship_from',
//   'invoice.middleman': 'invoice_middleman',
//   'invoice.supply': 'invoice_supply',
//   'invoice.order_acceptance': 'invoice_order_acceptance',
//   'invoice.order_origin': 'invoice_order_origin',
//   'invoice.buyer_primary': 'invoice_buyer_primary',
//   'invoice.seller_primary': 'invoice_seller_primary',
// };

const SBX_SORTABLE_FIELDS = new Set([
  'INVOICE.INVOICE_NUMBER',
  'INVOICE.COMPANY_ROLE',
  'INVOICE.CURRENCY_CODE',
  'INVOICE.EXTERNAL_COMPANY_ID',
  'INVOICE.COMPANY_NAME',
  'INVOICE.INVOICE_DATE',
  'INVOICE.TRANSACTION_TYPE',
  // 'INVOICE.registrations.buyer',
  // 'INVOICE.registrations.seller',
  // 'INVOICE.registrations.middleman',
  'INVOICE_SHIP_TO',
  'INVOICE_SHIP_FROM',
  'recordStatus',
]);

const SBX_FORMAT_FIELDS = {
  'INVOICE.COMPANY_ROLE': 'companyRole',
  'INVOICE.POINT_OF_TITLE_TRANSFER': 'poTT',
  'INVOICE.TRANSACTION_TYPE': 'transactionType',
  'EXPECTED.INVOICE.POINT_OF_TITLE_TRANSFER': 'poTT',
  'ACTUAL.INVOICE.POINT_OF_TITLE_TRANSFER': 'poTT',
  'EXPECTED.INVOICE.TAXABILITY': 'taxabilityType',
  'ACTUAL.INVOICE.TAXABILITY': 'taxabilityType',
  'EXPECTED.LINE.POINT_OF_TITLE_TRANSFER': 'poTT',
  'ACTUAL.LINE.POINT_OF_TITLE_TRANSFER': 'poTT',
  'EXPECTED.LINE.TAXABILITY': 'taxabilityType',
  'ACTUAL.LINE.TAXABILITY': 'taxabilityType',
  'EXPECTED.LINE.TRANS_TYPE': 'transactionType',
  'ACTUAL.LINE.TRANS_TYPE': 'transactionType',
  'RESULTS.LINE.TRANS_TYPE': 'transactionType',
  'LINE.UNIT_OF_MEASURE': 'unitOfMeasure',
};

const SBX_INVOICE_ADDRESS_FIELD_KEYS = new Set([
  'INVOICE.SHIP_FROM',
  'INVOICE.SHIP_TO',
  'INVOICE.BILL_TO',
  'INVOICE.BUYER_PRIMARY',
  'INVOICE.SELLER_PRIMARY',
  'INVOICE.ORDER_ACCEPTANCE',
  'INVOICE.ORDER_ORIGIN',
  'INVOICE.SUPPLY',
  'INVOICE.MIDDLEMAN',
  // To handle escaped column keys
  'INVOICE_SHIP_FROM',
  'INVOICE_SHIP_TO',
  'INVOICE_BILL_TO',
  'INVOICE_BUYER_PRIMARY',
  'INVOICE_SELLER_PRIMARY',
  'INVOICE_ORDER_ACCEPTANCE',
  'INVOICE_ORDER_ORIGIN',
  'INVOICE_SUPPLY',
  'INVOICE_MIDDLEMAN',
]);

const SBX_LINE_ADDRESS_FIELD_KEYS = new Set([
  'LINE.SHIP_FROM',
  'LINE.SHIP_TO',
  'LINE.BILL_TO',
  'LINE.BUYER_PRIMARY',
  'LINE.SELLER_PRIMARY',
  'LINE.ORDER_ACCEPTANCE',
  'LINE.ORDER_ORIGIN',
  'LINE.SUPPLY',
  'LINE.MIDDLEMAN',
  // To handle escaped column keys
  'LINE_SHIP_FROM',
  'LINE_SHIP_TO',
  'LINE_BILL_TO',
  'LINE_BUYER_PRIMARY',
  'LINE_SELLER_PRIMARY',
  'LINE_ORDER_ACCEPTANCE',
  'LINE_ORDER_ORIGIN',
  'LINE_SUPPLY',
  'LINE_MIDDLEMAN',
]);

const getAddressFieldName = function (name) {
  if (SBX_ADDRESS_FIELD_NAMES[name.toLowerCase()] === true) {
    return true;
  }

  const parsed = name
    ?.toLowerCase()
    ?.replace('INVOICE.', '')
    ?.replace('LINE.')
    ?.trim();

  if (SBX_ADDRESS_FIELD_NAMES[parsed] == true) {
    return parsed;
  }

  return null;
};

const addressFieldNameCompare = function (name1, name2) {
  const order1 = SBX_ADDRESS_FIELD_ORDERS[getAddressFieldName(name1)];
  const order2 = SBX_ADDRESS_FIELD_ORDERS[getAddressFieldName(name2)];

  if (order1 === 0) {
    if (order2 === 0) {
      return 0;
    }
    return -1;
  }

  if (!order1 && !order2) {
    return name1?.localeComparse(name2 || '') || 0;
  }

  if (!order1) {
    return 1;
  }

  if (!order2) {
    return -1;
  }

  return order1 - order2;
};

/**
 *
 * @param {Function} nameFieldRetriever The callback function to use for getting the field name from elements of the array.
 * @returns {Function} Array sort function
 */
const addressListSorter =
  (
    nameFieldRetriever = (address) => {
      if (typeof address === 'string') {
        return address;
      }

      return address.name || address.key;
    },
  ) =>
  (a, b) => {
    const nameA = nameFieldRetriever(a);
    const nameB = nameFieldRetriever(b);
    return addressFieldNameCompare(nameA, nameB);
  };

const labelAttribute = function (value) {
  const uc = `${value}`.toUpperCase().trim();
  if (uc.startsWith('LINE')) {
    return uc
      .replaceAll('LINE.ATTR', 'Attribute')
      .replaceAll('LINE ATTR', 'Attribute')
      .replaceAll('LINE.USER_ELEMENT.', 'Attribute ')
      .replaceAll('LINE.USER_ELEMENT_AMOUNT.', 'Amt. Attribute ')
      .replaceAll('LINE.USER_ELEMENT_DATE.', 'Date Attribute ');
  } else if (uc.startsWith('INVOICE')) {
    return uc
      .replaceAll('INVOICE.ATTR', 'Attribute')
      .replaceAll('INVOICE ATTR', 'Attribute')
      .replaceAll('INVOICE.USER_ELEMENT.', 'Attribute ')
      .replaceAll('INVOICE.USER_ELEMENT_AMOUNT.', 'Amt. Attribute ')
      .replaceAll('INVOICE.USER_ELEMENT_DATE.', 'Date Attribute ');
  } else if (uc.startsWith('ATTR')) {
    return uc.replace('ATTRIBUTE', 'Attribute').replace('ATTR', 'Attribute');
  }

  return value;
};

const SBX_TRANSACTION_TYPES = {
  AT: 'Ancillary Transport Services',
  BG: 'India SEZ - Buyer Goods',
  BS: 'India SEZ - Buyer Services',
  CL: 'Customer Location Services',
  DS: 'Default Services',
  ES: 'Electronic Services',
  GS: 'Goods',
  IN: 'Installation Services',
  MV: 'Movement',
  PC: 'Prime Contracting',
  PP: 'Physical Performance',
  RR: 'Rental',
  SG: 'India SEZ - Seller Goods',
  SI: 'Supplied and Installed',
  SS: 'India SEZ - Seller Services',
  T1: 'Chain Transaction 1',
  T2: 'Chain Transaction 2',
  TG: 'Telecommunication Services - General',
  TP: 'Telecommunication Services - Private',
  TS: 'Transport Services',
  WG: 'Work on Goods',
};

const isSbxInvoiceField = function (fieldName) {
  if (
    fieldName.startsWith('INVOICE_') ||
    fieldName.startsWith('INVOICE.') ||
    fieldName.startsWith('RESULTS.INVOICE')
  ) {
    return true;
  }
  // eslint-disable-next-line no-prototype-builtins
  return SBX_INVOICE_FIELDS_MAP.hasOwnProperty(fieldName);
};

const SBX_REWRITE_FIELDS = {};

const parseCustomAttributes = function (
  customAttributes,
  predicate = 'INVOICE',
) {
  if (!customAttributes) {
    return [];
  }

  let attrs = [];
  if (Object.prototype.hasOwnProperty.call(customAttributes, 'userElement')) {
    attrs = [
      ...customAttributes.userElement,
      ...customAttributes.amount,
      ...customAttributes.date,
    ];
  } else if (isArray(customAttributes)) {
    attrs = [...customAttributes];
  }

  return attrs.map((attr) => {
    if (attr.key && attr.label) {
      return attr;
    }

    const fieldPath = `${predicate}.${attr.type}.${attr.name}`.toUpperCase();
    const number = Number.parseInt(
      attr.name.split('.').pop().replace('ATTRIBUTE', ''),
    );
    let label;
    if (number < 10) {
      label = `Attribute 0${number}`;
    } else {
      label = `Attribute ${number}`;
    }

    return {
      key: fieldPath.replace('.', '_'),
      label,
      value: attr.value,
      fieldPath,
    };
  });
};

export {
  SBX_ADDRESS_FIELD_NAMES,
  SBX_ADDRESS_FIELD_ORDERS,
  SBX_FORMAT_FIELDS,
  SBX_INVOICE_ADDRESS_FIELD_KEYS,
  SBX_INVOICE_FIELDS,
  SBX_INVOICE_FIELDS_MAP,
  SBX_LINE_ADDRESS_FIELD_KEYS,
  SBX_LINE_FIELDS,
  SBX_LINE_FIELDS_MAP,
  SBX_REWRITE_FIELDS,
  SBX_SORTABLE_FIELDS,
  SBX_TRANSACTION_FIELDS,
  SBX_TRANSACTION_FIELDS_MAP,
  SBX_TRANSACTION_TYPES,
  parseCustomAttributes,
  addressFieldNameCompare,
  addressListSorter,
  labelAttribute,
  isSbxInvoiceField,
};
