<template>
  <div class="zone-address-type">
    <CRow v-if="hasCountryOrDivision || isLatLong">
      <CCol v-if="hasCountry && isLatLong">
        <span v-c-tooltip="latLongFullLabel" class="text-small"
          >{{ zone.country }}: ({{ latLongLabel }})</span
        >
      </CCol>
      <CCol v-else-if="hasCountryOrDivision">{{
        countryAndDivisionLabel
      }}</CCol>
      <CCol v-else>
        <span v-c-tooltip="latLongFullLabel" class="text-small"
          >({{ latLongLabel }})</span
        >
      </CCol>
    </CRow>
    <CRow v-if="detailed && addressLines.length > 0">
      <CCol>
        <div v-for="line in addressLines" :key="line" class="zone-address-line">
          {{ line }}
        </div>
      </CCol>
    </CRow>
    <CRow v-if="detailed && hasCityOrPostCode">
      <CCol>{{ cityAndPostCodeLabel }}</CCol>
    </CRow>
  </div>
</template>
<script>
function truncateLatLng(value) {
  const str = `${value}`;
  if (str.length < 8) {
    return str;
  } else {
    const parts = str.split('.');
    const postLen = parts[1].length;
    if (postLen <= 4) {
      return str;
    } else {
      return `${parts[0]}.${parts[1].substring(0, 4)}...`;
    }
  }
}
export default {
  name: 'ZoneAddressType',
  props: {
    value: {
      type: Object,
      required: false,
      default() {
        return null;
      },
    },
    detailed: {
      type: Boolean,
      required: false,
    },
    zoneType: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      zone: {
        ...this.parseValue(this.value),
      },
    };
  },
  computed: {
    addressLines() {
      return [
        this.zone.address1,
        this.zone.address2,
        this.zone.address3,
      ].filter((a) => !!a && a.length > 0);
    },
    isLatLong() {
      return (!!this.zone.latitude && !!this.zone.longitude) == true;
    },
    latLongFullLabel() {
      return `${this.zone.latitude}, ${this.zone.longitude}`;
    },
    latLongLabel() {
      if (this.isLatLong) {
        if (this.detailed) {
          return this.latLongFullLabel;
        } else {
          return `${truncateLatLng(this.zone.latitude)}, ${truncateLatLng(
            this.zone.longitude,
          )}`;
        }
      }
      return null;
    },
    countryAndDivisionLabel() {
      const c = this.zone.country;
      const d = this.zone.division;

      if (!d && !c) {
        return null;
      }

      if (!!c && !!d) {
        return `${d}, ${c}`;
      } else {
        return `${c || d}`;
      }
    },
    cityAndPostCodeLabel() {
      // const city = this.zone.city;
      // const county = this.zone.county;
      // const district = this.zone.district;
      // const postcode = this.zone.postalCode;
      return null;
    },
    hasCityOrPostCode() {
      return false;
    },
    hasCountryOrDivision() {
      return !!this.countryAndDivisionLabel;
    },
    hasCountry() {
      return !!this.zone.country;
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(v) {
        this.zone = this.parseValue(v);
      },
    },
  },
  methods: {
    parseValue(v) {
      if (!v) {
        return {
          country: null,
          division: null,
          county: null,
          district: null,
          city: null,
          postalCode: null,
          geoCode: null,
          address1: null,
          address2: null,
          address3: null,
          taxAreaId: null,
          locationCode: null,
          latitude: null,
          longitude: null,
        };
      }

      let postalCode = v.postcode?.trim() || v.postalCode?.trim() || null;
      const geoCode = v.geoCode?.trim() || null;
      if (postalCode && geoCode) {
        postalCode = `${postalCode}-${geoCode}`;
      }

      return {
        country: v.country?.trim() || null,
        division:
          v.division?.trim() ||
          v.mainDivision?.trim() ||
          v.province?.trim() ||
          v.state?.trim() ||
          v.region?.trim() ||
          null,
        county: v.county?.trim() || null,
        city: v.city?.trim() || null,
        district: v.district?.trim() || null,
        postalCode: postalCode,
        geoCode: v.geoCode?.trim() || null,
        address1: v.address1?.trim() || v.line1?.trim() || null,
        address2: v.address2?.trim() || v.line2?.trim() || null,
        address3: v.address3?.trim() || v.line3?.trim() || null,
        taxAreaId: v.taxAreaId?.trim() || null,
        locationCode: v.locationCode?.trim() || null,
        latitude: v.latitude || null,
        longitude: v.longitude || null,
      };
    },
  },
};
</script>
