'use strict';
class Address {
  constructor(obj = {}) {
    this.key = obj.key || null;
    this.fieldName = obj.fieldName || null;
    this.fieldPath = obj.fieldPath || obj.fieldName || null;
    this.taxEngineType = obj.taxEngineType || null;
    this.addressKey = obj.key || null;
    this.country = obj.country || null;
    this.city = obj.city || null;
    this.postcode = obj.postcode || obj.postalCode || null;
    this.geoCode = obj.geoCode || obj.geocode || null;
    this.district = obj.district || null;
    this.street = [obj.address1, obj.address2, obj.address3].filter((a) => {
      return !!a;
    });
    this.hasStreet = this.street.length > 0;
    this.mainDivisionLabel = 'main_division';
    this.mainDivision = obj.mainDivision || obj.state || obj.province;
    this.state = obj.state;
    this.province = obj.province;
    this.latitude = obj.latitude || null;
    this.longitude = obj.longitude || null;

    if (obj.state) {
      this.mainDivisionLabel = 'state';
    } else if (obj.province) {
      this.mainDivisionLabel = 'province';
    }

    this.taxAreaId = obj.taxAreaId;
    this.locationCode = obj.locationCode;
    this.hasLatLong = !!obj.latitude && !!obj.longitude;

    this.empty = !(
      this.country ||
      this.district ||
      this.city ||
      this.postcode ||
      this.geoCode ||
      this.mainDivision ||
      this.taxAreaId ||
      this.locationCode ||
      this.hasStreet ||
      this.hasLatLong
    );
  }

  static fromOneSource(zone, key = null, fieldPath = null) {
    if (typeof zone === Address) {
      return zone;
    }

    zone.key = key;
    let a = new Address(zone);
    a.key = key;
    a.taxEngineType = 'SBX';

    if (fieldPath) {
      a.fieldPath = fieldPath.toUpperCase();
      a.fieldName = fieldPath
        .toUpperCase()
        .replace('INVOICE.', '')
        .replace('LINE.', '')
        .trim();
    }

    return a;
  }

  static fromVertex(zone, key = null, fieldName = null) {
    if (typeof zone === Address) {
      return zone;
    }

    let a = new Address(zone);
    a.key = key;
    a.taxEngineType = 'VTX';
    a.fieldName = fieldName;
    a.fieldPath = fieldName;

    return a;
  }
}

export default Address;
