const VTX_TRANSACTION_FIELDS_JSON = require('@/views/testing/_resources/vtx-document-fields.json');
export const VTX_SORTABLE_FIELDS = new Set([
  'DOC_NUM',
  'COMPANY',
  'DIVISION',
  'DEPARTMENT',
  'DOC_CURRENCY_CODE',
  'DOC_DATE',
  'DOC_TRANS_TYPE',
  'recordStatus',
  'DOC_DEST',
  'DOC_PHYS_ORIGIN',
]);

export const VTX_TRANSACTION_TYPES = {
  SALE: 'Sale',
  PURCHASE: 'Purchase',
};

export const VTX_TRANSACTION_FIELDS = VTX_TRANSACTION_FIELDS_JSON.map((f) => {
  return {
    ...f,
    key: f['key'].replaceAll(' ', '_'),
    _rawKey: f['key'],
  };
});

export const VTX_TRANSACTION_FIELDS_MAP = VTX_TRANSACTION_FIELDS.reduce(
  (acc, o) => {
    acc[o.key] = o;
    return acc;
  },
  {},
);

export const VTX_DOC_FIELDS = VTX_TRANSACTION_FIELDS.filter((field) => {
  if (field.key.startsWith('DOC') || field.isDocument === true) {
    return true;
  }

  if (field.key.startsWith('LINE') || field.isLine === true) {
    return false;
  }

  switch (field.key) {
    case 'COMPANY':
    case 'DIVISION':
    case 'DEPARTMENT':
      return true;
    default:
      if (
        field.key.startsWith('EXPECTED DOC') ||
        field.key.startsWith('ACTUAL DOC')
      ) {
        return true;
      }
      return false;
  }
});

export const VTX_DOC_FIELDS_MAP = VTX_DOC_FIELDS.reduce((acc, o) => {
  acc[o.key] = o;
  return acc;
}, {});

export const VTX_LINE_FIELDS = VTX_DOC_FIELDS.filter((field) => {
  if (field.key.startsWith('LINE') || field.isLine === true) {
    return true;
  }

  return !VTX_DOC_FIELDS_MAP[field.key];
});

export const VTX_LINE_FIELDS_MAP = VTX_LINE_FIELDS.reduce((acc, o) => {
  acc[o.key] = o;
  return acc;
}, {});

export const VTX_ADDRESS_PREFIXES = [
  'PHYS_ORIGIN',
  'DEST',
  'ADMIN_DEST',
  'ADMIN_PHYS_ORIGIN',
];

export const VTX_DOC_ADDRESS_PREFIXES = VTX_ADDRESS_PREFIXES.map((p) => {
  return `DOC_${p}`;
});

const VTX_LINE_ADDRESS_PREFIXES = VTX_ADDRESS_PREFIXES.map((p) => {
  return `LINE_${p}`;
});

export function convertPropertyName(name) {
  const converted = name.replaceAll(' ', '_').toUpperCase();
  // eslint-disable-next-line no-prototype-builtins
  const found = VTX_TRANSACTION_FIELDS_MAP.hasOwnProperty(converted);
  if (found) {
    return converted;
  }

  return [...VTX_DOC_ADDRESS_PREFIXES, ...VTX_LINE_ADDRESS_PREFIXES].find(
    (p) => {
      return converted.startsWith(p);
    },
  );
}

export function getFieldForProperty(name) {
  if (!name) {
    return null;
  }

  // eslint-disable-next-line no-prototype-builtins
  if (VTX_TRANSACTION_FIELDS_MAP.hasOwnProperty(name)) {
    return VTX_TRANSACTION_FIELDS_MAP[name];
  }

  const converted = convertPropertyName(name);
  if (converted) {
    return VTX_TRANSACTION_FIELDS_MAP[converted];
  }

  return null;
}

export function getDocumentFieldName(name) {
  if (!name) {
    return null;
  }

  // eslint-disable-next-line no-prototype-builtins
  if (VTX_DOC_FIELDS_MAP.hasOwnProperty(name)) {
    return name;
  }

  const converted = convertPropertyName(name);
  if (converted) {
    return VTX_DOC_FIELDS_MAP[converted]?.key;
  }

  return null;
}

export function getDocumentAddressField(name) {
  const match = VTX_DOC_ADDRESS_PREFIXES.find((prefix) => {
    return name.startsWith(prefix);
  });

  if (match) {
    return VTX_DOC_FIELDS_MAP[match];
  }

  return null;
}

export function getFieldLabel(name) {
  return VTX_TRANSACTION_FIELDS_MAP[name]?.label || name;
}

const ROW_LINE_PRODUCT_PROPS = [
  ['productCode', 'PRODUCT_CODE'],
  ['productClass', 'PRODUCT_CLASS'],
  ['commoditCode', 'COMMODITY_CODE'],
  ['commodityCodeType', 'COMMODITY_CODE_TYPE'],
  ['materialCode', 'MATERIAL_CODE'],
  ['sku', 'SKU'],
];

const ROW_LINE_PURCH_PRODUCT_PROPMAP = {
  productCode: 'PURCHASE',
  productClass: 'PURCHASE_CLASS',
};

export function mapLineProduct(product) {
  if (!product) {
    return [];
  }

  return ROW_LINE_PRODUCT_PROPS.map((prop) => {
    const key = prop[0];
    if (product[key]) {
      if (
        product.isPurchase == true &&
        // eslint-disable-next-line no-prototype-builtins
        ROW_LINE_PURCH_PRODUCT_PROPMAP.hasOwnProperty(key)
      ) {
        return {
          type: ROW_LINE_PURCH_PRODUCT_PROPMAP[key],
          code: product[key],
        };
      } else {
        return {
          type: prop[1],
          code: product[key],
        };
      }
    } else {
      return null;
    }
  }).filter((p) => {
    return !!p;
  });
}
