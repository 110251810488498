<template>
  <VSelect
    :id="id"
    v-model="selectedValue"
    multiple
    :options="transactionTypeOptions"
    track-by="value"
    label="label"
    searchable
    :close-on-select="false"
    :limit="3"
    @open="
      () => {
        vselectOpen = true;
      }
    "
    @close="handleClose"
  >
    <template #tag="{ option, remove }">
      <span class="multiselect__tag">
        <span>{{ option.value }}</span>
        <i
          class="multiselect__tag-icon"
          aria-hidden="true"
          @click="remove(option)"
        />
      </span>
    </template>
  </VSelect>
</template>
<script>
import vSelect from 'vue-multiselect';
import { VTX_TRANSACTION_TYPES } from '@/utils/vtxFieldUtils';
const VTX_TRANSACTION_TYPES_OPTIONS = Object.freeze(
  Object.keys(VTX_TRANSACTION_TYPES).map((key) => {
    return {
      value: key,
      label: VTX_TRANSACTION_TYPES[key],
    };
  }),
);

export default {
  name: 'VtxTransactionTypePicker',
  components: {
    VSelect: vSelect,
  },
  props: {
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    invalidFeedback: {
      type: String,
      required: false,
      default: null,
    },
    isValid: {
      type: Boolean,
      required: false,
      default: null,
    },
    value: {
      type: [String, Object, Array],
      required: false,
      default() {
        return null;
      },
    },
    label: {
      type: String,
      default: 'Transaction Type',
    },
    id: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      internalValue: this.mapValue(VTX_TRANSACTION_TYPES_OPTIONS, this.value),
      transactionTypeOptions: VTX_TRANSACTION_TYPES_OPTIONS,
      vselectOpen: false,
    };
  },
  computed: {
    selectedValue: {
      get() {
        return this.internalValue;
      },
      set(v) {
        this.internalValue = v;
      },
    },
  },
  watch: {
    defaultValue: {
      immediate: true,
      handler(v) {
        if (v) {
          this.internalValue = this.mapValue(v);
        } else {
          this.internalValue = [];
        }
      },
    },
  },
  methods: {
    mapValue(options, values) {
      if (!values) {
        return null;
      }

      const arrValues = !Array.isArray(values) ? [values] : values;
      return arrValues
        .map((i) => {
          return options.find((o) => {
            return o.value === i || i.value;
          })?.value;
        })
        .filter((i) => !!i);
    },
    handleClose() {
      this.$nextTick(() => {
        this.$emit(
          'update:value',
          this.internalValue.map((i) => i.value),
        );
      });
    },
  },
};
</script>
