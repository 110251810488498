import svgMap from 'svgmap';

/**
 * Map shipTo/shipFrom geos to data for an SVG map.
 *
 * @param {*} shipToGeos Map of geos being shipped to
 * @param {*} shipFromGeos Map of geos being shipped from
 * @returns {*} Result count
 */
export function mapCountryDataForSvg(shipToGeos, shipFromGeos) {
  const countries = Object.keys(shipToGeos || {}).reduce((map, key) => {
    const countryKey = key.split('-')[0];
    const value = shipToGeos[key] || 0;
    if (!map[countryKey]) {
      map[countryKey] = {
        shipTo: value,
        shipFrom: 0,
        total: value,
      };
    } else {
      map[countryKey].shipTo += value;
      map[countryKey].total += value;
    }
    return map;
  }, {});

  Object.keys(shipFromGeos || {}).forEach((key) => {
    const value = shipFromGeos[key] || 0;
    const countryKey = key.split('-')[0];
    if (!countries[countryKey]) {
      countries[countryKey] = {
        shipTo: 0,
        shipFrom: value,
        total: value,
      };
    } else {
      countries[countryKey].shipFrom += value;
      countries[countryKey].total += value;
    }
  });

  return countries;
}

export function createSvgTestDeckMap(targetElementID, countries) {
  return new svgMap({
    targetElementID: targetElementID,
    colorMax: '#0D7930',
    colorMin: '#D6FAE2',
    noDataText: 'No relevant test data',
    flagURL: '/img/countries/svg/{0}.svg',
    data: {
      applyData: 'shipTo',

      data: {
        shipTo: {
          name: 'Ship To',
        },
        shipFrom: {
          name: 'Ship From',
        },
        total: {
          name: 'Total',
        },
      },
      values: countries,
    },
  });
}
