<template>
  <fieldset class="d-inline-flex">
    <legend>{{ label }}</legend>
    <template v-if="hasCounterParty">
      <LabeledValue label="Name" :empty="!name">
        {{ name || '--' }}
      </LabeledValue>
      <LabeledValue label="Number" :empty="!number">
        {{ number || '--' }}
      </LabeledValue>
      <!--
      <LabeledValue label="Registration" :empty="!registration">
        {{ registration || '--' }}
      </LabeledValue>
      -->
    </template>
    <div v-else class="h5 text-muted mt-2 ml-1">None</div>
  </fieldset>
</template>
<script>
export default {
  name: 'SbxCounterPartyBlock',
  props: {
    label: {
      type: String,
      required: false,
      default: 'Counter Party',
    },
    name: {
      type: String,
      required: false,
      default: null,
    },
    number: {
      type: String,
      required: false,
      default: null,
    },
    registration: {
      type: String,
      required: false,
      default: null,
    },
  },
  computed: {
    hasCounterParty() {
      return !!(this.number || this.name);
    },
  },
};
</script>
