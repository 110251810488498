const AVA_TRANSACTION_FIELDS_JSON = require('./ava-transaction-fields.json');

export const AVA_FIELDS_BY_KEY = AVA_TRANSACTION_FIELDS_JSON.reduce(
  (acc, f) => {
    acc[f.key] = f;
    return acc;
  },
  {},
);

export const AVA_FIELDS_BY_ESCAPED = AVA_TRANSACTION_FIELDS_JSON.reduce(
  (acc, f) => {
    acc[f.escaped] = f;
    return acc;
  },
  {},
);

export const AVA_DOC_TABLE_COLS = AVA_TRANSACTION_FIELDS_JSON.reduce(
  (acc, f) => {
    if (f.isDocument) {
      acc.push({
        label: f.label,
        key: f.escaped,
        sortable: f.sortable,
        fieldType: f.fieldType,
      });
    }
    return acc;
  },
  [],
);

export const AVA_FORMAT_FIELDS = {
  'EXPECTED.DOCUMENT.TAXABILITY': 'nextGenTaxabilityType',
  'EXPECTED.LINE.TAXABILITY': 'nextGenTaxabilityType',
};

export const AVA_FORMAT_FIELDS_KEYS = Object.keys(AVA_FORMAT_FIELDS);

export function getAddressFieldForProperty(name) {
  const parts = name.split('.');
  if (parts.length > 2) {
    const joined = `${parts[0]}.${parts[1]}`;
    return AVA_FIELDS_BY_KEY[joined];
  } else {
    return undefined;
  }
}
export function getFieldForProperty(name) {
  if (!name) {
    return null;
  }

  const field = AVA_FIELDS_BY_KEY[name] || AVA_FIELDS_BY_ESCAPED[name];
  if (field) {
    return field;
  }

  return getAddressFieldForProperty(name);
}
export function isIgnoredField(name) {
  const field = getFieldForProperty(name);
  if (!field) return true;
  if (field.isAddress) return true;
  if (field.isIgnored) return true;
  if (
    field.key.includes('EXPECTED.') ||
    field.key.includes('USER_DEFINED_FIELDS.')
  )
    return true;
  return false;
}
