<template>
  <fieldset class="d-inline-flex">
    <legend>Registrations</legend>
    <template v-if="hasRegistrations">
      <LabeledValue label="Buyer" :empty="!buyerRegistrations">
        {{ buyerRegistrations || '--' }}
      </LabeledValue>
      <LabeledValue label="Seller" :empty="!sellerRegistrations">
        {{ sellerRegistrations || '--' }}
      </LabeledValue>
      <LabeledValue label="Middleman" :empty="!middlemanRegistrations">
        {{ middlemanRegistrations || '--' }}
      </LabeledValue>
    </template>
    <template v-else>
      <div class="h5 text-muted mt-2 ml-1">None</div>
    </template>
  </fieldset>
</template>
<script>
export default {
  name: 'SbxRegistrationsBlock',
  props: {
    buyer: {
      type: String,
      required: false,
      default: null,
    },
    seller: {
      type: String,
      required: false,
      default: null,
    },
    middleman: {
      type: String,
      required: false,
      default: null,
    },
  },
  computed: {
    hasRegistrations() {
      return !!(this.buyer || this.seller || this.middleman);
    },
    sellerRegistrations() {
      if (!this.seller) {
        return null;
      }

      return this.seller
        .split('|')
        .map((o) => o.trim())
        .join(', ');
    },
    buyerRegistrations() {
      if (!this.buyer) {
        return null;
      }

      return this.buyer
        .split('|')
        .map((o) => o.trim())
        .join(', ');
    },
    middlemanRegistrations() {
      if (!this.middleman) {
        return null;
      }

      return this.middleman
        .split('|')
        .map((o) => o.trim())
        .join(', ');
    },
  },
};
</script>
