<template>
  <div class="document-address-container">
    <div class="document-address-label">
      <slot name="label">
        <template v-if="!!label">{{ label }}</template>
      </slot>
    </div>
    <div v-if="locationCode" class="address-value">
      <label class="text-muted text-small" :for="`${id}_locationCode`"
        >Location Code</label
      >
      <div :id="`${id}_locationCode`">{{ locationCode }}</div>
    </div>
    <div v-if="hasLatLong" class="address-value">
      <label class="text-muted text-small" :for="`${id}_latitude`"
        >Latitude</label
      >
      <div :id="`${id}_latitude`">{{ latitude }}</div>
    </div>
    <div v-if="hasLatLong" class="address-value">
      <label class="text-muted text-small" :for="`${id}_longitude`"
        >Longitude</label
      >
      <div :id="`${id}_longitude`">{{ longitude }}</div>
    </div>
    <div v-if="hasStreet" class="document-address-geo-container">
      <label class="text-muted text-small">Address</label>
      <div
        v-for="(line, index) in street"
        :key="`${index}_${line}`"
        class="document-address-line"
      >
        {{ line }}
      </div>
    </div>
    <div
      v-if="city || district || postcode"
      class="d-inline-flex document-address-geo-container"
    >
      <div v-if="city" class="address-value">
        <label class="text-muted text-small" :for="`${id}_city`">City</label>
        <div :id="`${id}_city`">{{ city }}</div>
      </div>
      <div v-if="district" class="address-value">
        <label class="text-muted text-small" :for="`${id}_district`"
          >District</label
        >
        <div :id="`${id}_district`">{{ district }}</div>
      </div>
      <div v-if="postcode" class="address-value">
        <label class="text-muted text-small" :for="`${id}_postcode`"
          >Postcode</label
        >
        <div :id="`${id}_postcode`">{{ postcode }}</div>
      </div>
    </div>
    <div
      v-if="country || mainDivision"
      class="d-inline-flex document-address-geo-container"
    >
      <div v-if="mainDivision" class="address-value">
        <label class="text-muted text-small" :for="`${id}_main`">{{
          mainDivisionLabel
        }}</label>
        <div :id="`${id}_main`">{{ mainDivision }}</div>
      </div>
      <div v-if="country" class="address-value">
        <label class="text-muted text-small" :for="`${id}_country`"
          >Country</label
        >
        <div :id="`${id}_country`">{{ country }}</div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'DocumentAddress',
  props: {
    label: {
      type: String,
      required: false,
      default: null,
    },
    value: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      ...this.parse(this.value),
    };
  },
  computed: {
    id() {
      return this.addressKey || this.$attrs.id || this.$props.id || this._uid;
    },
  },
  methods: {
    parse(value) {
      const region = value.region;
      const country = value.country;
      const province = value.province;
      const state = value.state;
      const city = value.city;
      let postcode = value.postcode || value.postalCode || null;
      const geoCode = value.geoCode || value.geocode || null;

      if (postcode && geoCode) {
        postcode = `${postcode}-${geoCode}`;
      }

      const district = value.district;
      const latitude = value.latitude;
      const longitude = value.longitude;
      const locationCode = value.locationCode;
      const street = [];
      if (value.address1) {
        street.push(value.address1);
      }

      if (value.address2) {
        street.push(value.address2);
      }

      if (value.address3) {
        street.push(value.address3);
      }

      let mainDivisionLabel = value.mainDivisionLabel || null;
      if (!mainDivisionLabel) {
        if (state) {
          mainDivisionLabel = 'state';
        } else if (province) {
          mainDivisionLabel = 'province';
        } else if (region) {
          mainDivisionLabel = 'region';
        } else {
          mainDivisionLabel = 'main_division';
        }
      }

      return {
        addressKey: value.key || null,
        country,
        mainDivision:
          province ||
          state ||
          region ||
          value.division ||
          value.mainDivision ||
          null,
        mainDivisionLabel: this.$t(
          `address.division.${mainDivisionLabel}`.toLowerCase(),
        ),
        city,
        postcode,
        geoCode,
        district,
        street,
        latitude,
        longitude,
        locationCode,
        hasStreet: street.length > 0,
        hasLatLong: value.hasLatLong || (value.latitude && value.longitude),
      };
    },
  },
};
</script>
<style lang="scss">
.document-address-container {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;

  .document-address-label {
    font-size: 0.9rem;
    font-weight: 600;
    border-bottom: 1px solid #eee;
    padding-bottom: 0.25rem;
  }

  label {
    margin-bottom: 0;
    padding-bottom: 0;
    width: 100%;
    font-size: 80%;
  }

  .address-value {
    padding: 0.25rem 0.5rem;
  }

  .document-address-geo-container {
    width: 100%;
    margin-left: -0.5rem;
  }
}
</style>
